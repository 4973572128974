import React from 'react';
import Layout from '../../../components/layouts';
import SEO from '../../../components/common/SEO';
import Banner from '../../../components/features/Banner'
import { graphql } from 'gatsby';
import SignUpBtn from '../../../components/features/SignUpBtn'
import Badge from '../../../components/badge/badge';
import ReactWOW from 'react-wow';
import './kudos.scss';

export const query = graphql`
  query kudos {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
    features: prismic {
        allFeatures {
            edges{
                node {
                    meta_title
                    meta_description
                    social_title
                    social_description
                    banner_title
                    banner_subtitle
                    banner_feature_name
                    banner_right_button_text
                    banner_right_button_source
                    question_text
                    question_answer
                    question_bullets {
                        bullet_text
                    }
                    question_image
                    video_source {
                        _linkType
                        __typename
                    }
                    video_statement {
                        text
                    }
                    information_list {
                        title
                        subtitle
                    }
                    steps_title
                    steps_subtitle
                    step_list {
                        image_source
                        title
                        subtitle
                    }
                    
                }
            }
        }
    }
  }
`

 export default function Kudos({data}){
    const features = data.features.allFeatures;
    const seo = {
        title: 'Workhub | Kudos and Core Values',
        description: 'Capture those special moments by giving a coworker a kudo, where workers explain how they went above-and-beyond, say which of you core values their coworker embodied, and share it to the Kudo Board to spread the story.',
        image: '/features/kudos/kudos-hero.png',
    }

    const bannerProps = {
        title: 'Kudos & Core Values',
        featureName: 'kudos',
        mockupImg: '/features/kudos/kudos-hero.png'

    }

    return (
        <Layout>
            <SEO {...seo} {...data.site}/>
            <Banner {...bannerProps}/>
            <main className="feature-main">
                <section className="feature-section kudos_header">
                    <div className='kudos_header'>
                        <h1>Company Core Values Exemplified</h1>
                        <subtitle>Celebrate your workforce going above and beyond</subtitle>
                    </div>
                    <div className='kudos_feature_info'>
                        <p>Industry best practices and research shows that worker recognition is a paramount element to having a happy and healthy company culture. Being able to give a “job well done” or an “atta-boy” is one tool in the toolbox to promote that kind of company culture. </p>
                        <p>A "kudo" has become a common way to offer praise and recognition in various settings, including the workplace, educational institutions, and social interactions. Workhub’s Kudos & Core Values component allows for workers to post instances of success of their peers, choose which of your organization’s core values those actions embodied, and share it with the workforce.</p>
                    </div>
                    <div className='kudos_feature_points'>
                        <div className='kudos_feature_list'>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Recognize Exemplary Actions:</strong> </p>
                            <div>
                                <p>Research shows that giving praise to someone for a behavior makes them more likely to repeat that action in the future. Kudos & Core Values is an easy venue for this praise to happen.</p>
                            </div>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p><strong>Promote Organizational Alignment:</strong> </p>
                            <p>All kudo posts have to be linked to one of your organization’s core values, making your company culture top-of-mind. The more exposure an worker has to your core values, the more likely they are to act in alignment with them.</p>

                            <img src='/app-icons/layout-icons/check-mark-line.svg' alt="checkmark"  />
                            <p> <strong>Reward Contribution:</strong> </p>
                            <p>Kudos & Core Values has flexible settings to allow rewarding points to receiver and/or sender, allowing you to encourage participation in the program. You can also choose how frequently workers are able to participate to match your needs and/or processes.</p>
                        </div>
                    </div>
                </section>
                <section className='feature-section'>
                    <SignUpBtn/>
                </section>

                <section className='feature-section'>
                    <div className='kudos_feature_options'>
                    <ReactWOW animation="fadeInLeft" delay="0.1s">
                        <div className='kudos_images_overlap'>
                            <img src="/features/kudos/kudos-template.png" alt="kudos worker page" />
                        </div>
                    </ReactWOW>
                        <div>
                            <h1>Welcome to the Kudo Board</h1>
                            <p>
                                The Kudo Board is your hub to see your organization’s collection of success stories. Watch your board grow as more special and unique moments trickle in. 
                                <br />
                                <br />
                                Color-coded core values help identify trends and commonly reoccurring themes. 
                            </p>
                        </div>
                    </div>
                    <div className='kudos_feature_options'>
                        <div>
                            <h1>Core Values Aligned to Your Work</h1>
                            <p>
                            Core Values are an integral part of an organization’s identity. They help align your workforce towards a common identity, mentality and thought process. Workhub has integrated core values with the kudo-giving process to help remind your workforce of your values. The more exposure your workforce has to your core values, the more likely they are to act in alignment with them.
                            </p>
                        </div>
                        <ReactWOW animation="fadeInRight" delay="0.1s">
                            <div> 
                                <img src="/features/kudos/kudos.png" alt="kudos celebration picture" />
                            </div>
                        </ReactWOW>
                    </div>
                </section>
                <section className='feature-section steps' id="overview-cards"> 
                    <h1>How it Works</h1>
                    <div className='kudos_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="1" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.1s">
                            <div className='_option'>
                                <p className='step-header'>Setup Your Program</p>
                                <p>Enter your organization’s core values, choose how frequently workers can send kudos, and how many points are earned per submission.</p>
                            </div>
                        </ReactWOW>
                    </div>

                    <div className='kudos_how_it_works'>
                    <ReactWOW animation="fadeInLeft" delay="0.2s">
                        <div className='_option'>
                            <p className='step-header'>Let Your Company Culture Grow</p>
                            <p>Workers can submit stories of peers embodying your organizations core values, allowing your organization to celebrate excellence.</p>
                        </div>
                    </ReactWOW>

                        <Badge className="badge" content="2" size="large"/>
                        <div className='empty'></div>
                    </div>

                    <div className='kudos_how_it_works'>
                        <div className='empty'></div>
                        <Badge className="badge" content="3" size="large"/>
                        <ReactWOW animation="fadeInRight" delay="0.3s">
                            <div className='_option'>
                                <p className='step-header'>See the Results</p>
                                <p>Visibly see your company culture grow as your Kudo Board fills with submissions from your workforce.</p>
                            </div>
                        </ReactWOW>

                    </div>

                </section>
            </main>
        </Layout>
    )
 }